<template>
  <div class="container">
    <h1>Lapis Kukus Industries Privacy Policy</h1>

    <p><strong>Last Updated on {{ lastUpdatedDate }}</strong></p>

    <section>
      <h2>Information We Collect</h2>
      <p>{{ informationCollection }}</p>
    </section>

    <section>
      <h2>How We Use Your Information</h2>
      <p>{{ informationUsage }}</p>
    </section>

    <section>
      <h2>How We Share Your Information</h2>
      <p>{{ informationSharing }}</p>
    </section>

    <section>
      <h2>Security of Your Information</h2>
      <p>{{ informationSecurity }}</p>
    </section>

    <section>
      <h2>Access and Control of Your Information</h2>
      <p>{{ informationAccessControl }}</p>
    </section>

    <section>
      <h2>Changes to the Privacy Policy</h2>
      <p>{{ policyChanges }}</p>
    </section>

    <section>
      <h2>Contact Us</h2>
      <p>If you have questions or comments about our privacy policy, please contact us at {{ contactEmail }}.</p>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lastUpdatedDate: '[Last Updated Date]',
      informationCollection: 'We may collect personal information you provide to us, such as your name, email address, phone number, or other information when you use our app. We may also collect non-personal information such as usage data, statistics, and other technical information.',
      informationUsage: 'We may use the information we collect to provide requested services, manage your account, send notifications, updates, and information related to our services, conduct data analysis to improve the quality of our services, and address technical or security issues.',
      informationSharing: 'We may share your information with third parties in certain cases, such as to comply with laws, regulations, or official requests, to protect our rights, property, or the safety of our users or other third parties, or in connection with changes in ownership, mergers, acquisitions, or company sales.',
      informationSecurity: 'We maintain the security of your information by implementing appropriate protective measures. However, no method of data transmission or storage is 100% secure. We work diligently to protect your information but cannot guarantee absolute security.',
      informationAccessControl: 'You have the right to access, update, or delete your personal information. You can also choose to stop using our app at any time.',
      policyChanges: 'We may update this privacy policy from time to time. Any changes will be posted here with the updated change date.',
      contactEmail: 'des.jatim1@gmail.com'
    };
  }
};
</script>

<style scoped>
.container {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
}

h1 {
  text-align: center;
}

section {
  margin-bottom: 20px;
}

h2 {
  font-size: 1.2em;
}

p {
  font-size: 1em;
  line-height: 1.4;
}
</style>
